import React,{useState,useContext} from 'react'
import lupaIcon from '../../images/png/lupaIcon.png'
import companyIcon from '../../images/png/logo.png'
import cardapioIcon from '../../images/png/cardapio.png'
import closeIcon from '../../images/png/close.png'
import { GlobalContext } from '../../context/global'

const HeaderMobile = () => {
    
    const [state,setState] = useState(false)

    const {handleSidebar} = useContext(GlobalContext)

    return (

        <div className='header_mobile'>

            <img src={companyIcon}/>
            {state == false 
            
            ?
            <img src={cardapioIcon} onClick={() => {setState(true);handleSidebar(true)}} className='menu_icon'/>
        
            :
            <img src={closeIcon}  onClick={() => {setState(false);handleSidebar(false)}} className='menu_icon'/>

            }


        </div>

    )
}

export default HeaderMobile
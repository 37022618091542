import React, { useContext, useEffect } from 'react'
import Slider from "react-slick";
import companyIcon from '../../images/png/logo.png'
import Card from '../../components/Card';
import banner from '../../images/png/teste-7.jpg'
import banner2 from '../../images/png/teste-8.jpg'
import creditCardIcon from '../../images/png/Site-blindado.png'
import lupaIcon from '../../images/png/lupaIcon.png'
import HeaderMobile from '../../components/HeaderMobile';
import nextArrow from '../../images/png/next.png'
import backArrow from '../../images/png/back.png'
import facebookIcon from '../../images/png/facebook.png'
import instagramIcon from '../../images/png/instagram.png'
import youtubeIcon from '../../images/png/youtube.png'
import whatsapp from '../../images/png/whatsapp.png'

/* PRODUCTS IMAGES */
import fraldaMIcon from '../../images/png/M_FRENTE.jpg'
import fraldaGIcon from '../../images/png/fraldaG.jpg'
import fraldaXGIcon from '../../images/png/XG_FRENTE.jpg'
import absIcon from '../../images/png/ABS_FRENTE.jpg'

import SliderMobile from '../../components/SliderMobile';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { GlobalContext } from '../../context/global';

const Landing = () => {

    const {sidebarState} = useContext(GlobalContext)

    const CustomPrevArrow = (props) => (
        <div {...props} className="custom-prev-arrow">
            <img src={backArrow} style={{ width: "40px" }} />

        </div>
    );



    const CustomNextArrow = (props) => (
        <div {...props} className="custom-next-arrow">
            <img src={nextArrow} style={{ width: "40px" }} />
        </div>
    )

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: true,

        infinite: false, // ou true, dependendo do comportamento desejado
        slidesToShow: 4,
        speed: 1000,
        slidesToScroll: 3,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

    };


    const settingsMobile = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "00px",
        slidesToShow: 1,
        speed: 500,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário
    };

    useEffect(() => {

        console.log(sidebarState)


    },[sidebarState])

    return (
        <>
            <main>
                <header>
                    <img src={companyIcon} />

                    <div className='container_input_search'>
                        <img src={lupaIcon} />
                        <input className='input_search' placeholder='Pesquisar Produto' type='text' />
                    </div>

                    <nav>
                        <ul>
                            <li>Home</li>
                            <li>Sobre</li>
                            <li>Produtos</li>
                            <li>Blog</li>
                            <li>Contato</li>

                        </ul>
                    </nav>


                </header>

                {sidebarState == true 
                ?

                <div className='sidebar_mobile_open'>
                <img src={companyIcon} />

                    <ul>
                        <li>Home</li>
                        <li>Minha Conta</li>
                        <li>Produtos</li>
                    </ul>
    

                </div>            
                :

                
                <div className='sidebar_mobile_not_open'>
                <img src={companyIcon} />

                    <ul>
                        <li>Home</li>
                        <li>Minha Conta</li>
                        <li>Produtos</li>
                    </ul>
    

                </div>
                
                }


                <HeaderMobile />


                <div className='carrousel_mobile'>
                    <Carousel showThumbs={false} showStatus={false}>
                        <div style={{height:"200px"}}>
                            <img style={{height:"200px"}} src={banner} />
                        </div>
                        <div style={{height:"200px"}}>
                            <img style={{height:"200px"}} src={banner2} />
                        </div>

                    </Carousel>

                </div>

                <section className='carrousel'>
                    <Carousel showStatus={false} showThumbs={false}>
                        <div style={{height:"420px"}}>
                            <img style={{height:"420px"}} src={banner} />
                        </div>
                        <div style={{height:"420px"}}>
                        <img style={{height:"420px"}} src={banner2} />
                        </div>

                    </Carousel>
                </section>

                <section className='products'>
                    <p className='textPrimaryProducts'>Produtos em Oferta</p>


                    <div className='slider_desktop'>
                        <Slider {...settings2}>

                            <Card star={3} description={"Fralda Geriátrica Descartável Tamanho M Pacote 50 unidades"} image={fraldaMIcon} value={"74,90"} />
                            <Card star={4} description={"Fralda Geriátrica Descartável Tamanho G Pacote 50 unidades"} image={fraldaGIcon} value={"79,90"} discount={true} />
                            <Card star={2} description={"Fralda Geriátrica Descartável Tamanho XG Pacote 50 unidades"} image={fraldaXGIcon} value={"69,90"} />
                            <Card star={5} discount={true} description={`Absorvente Geriátrico Pacote Descartável 50 unidades`} image={absIcon} value={"29,90"} />
                            <Card star={5} discount={true} description={`Absorvente Geriátrico Pacote Descartável 50 unidades`} image={absIcon} value={"29,90"} />


                        </Slider>
                    </div>


                    <div className='slider_mobile'>
                        <Slider {...settingsMobile}>

                            <Card star={3} description={"Fralda Geriátrica Descartável Tamanho M Pacote 50 unidades"} image={fraldaMIcon} value={"74,90"} />
                            <Card star={4} description={"Fralda Geriátrica Descartável Tamanho G Pacote 50 unidades"} image={fraldaGIcon} value={"79,90"} discount={true} />
                            <Card star={2} description={"Fralda Geriátrica Descartável Tamanho XG Pacote 50 unidades"} image={fraldaXGIcon} value={"69,90"} />
                            <Card star={5} discount={true} description={`Absorvente Geriátrico Pacote Descartável 50 unidades`} image={absIcon} value={"29,90"} />
                            <Card star={5} discount={true} description={`Absorvente Geriátrico Pacote Descartável 50 unidades`} image={absIcon} value={"29,90"} />


                        </Slider>
                    </div>



                    <div className='clean'></div>

                </section>

            </main>
            <footer>

                <div className='columPrimary_footer'>

                    <div className='container_footer_cards'>

                        <div className='card_primary_footer'>

                            <p className='textPrimary_card_primary_footer'>INSTITUCIONAL</p>

                            <p className='text_secondary_card_primary_footer'>Sobre</p>
                            <p className='text_secondary_card_primary_footer'>Prazos de Entrega</p>
                            <p className='text_secondary_card_primary_footer'>Trocas e devoluções</p>
                            <p className='text_secondary_card_primary_footer'>Política de Privacidade</p>


                            <p className='text_secondary_card_primary_footer'>Blog</p>
                            <p className='text_secondary_card_primary_footer'>Contato</p>


                        </div>

                        <div className='card_secondary_footer'>
                            <p className='textPrimary_card_primary_footer'>SITE CERTIFICADO</p>

                            <img src={creditCardIcon} />
                        </div>


                        <div className='card_three_footer'>

                            <p className='textPrimary_card_primary_footer'>RENOVAMED DISTRIBUIDORA </p>

                            <p className='text_secondary_card_primary_footer'> Rua José Bueno, 93 Jardim Cruzeiro  <br />Santa Isabel - SP, 07500-000  </p>
                            <p className='text_secondary_card_primary_footer'> CNPJ 47.211.449/0001-62</p>

                        </div>


                        <div className='card_four_footer'>

                            <p className='textPrimary_card_primary_footer'>FALE CONOSCO </p>

                            <p className='text_secondary_card_primary_footer'>Telefone (11) 4130-7128</p>
                            <p className='text_secondary_card_primary_footer'>Email contato@higemais.com.br</p>

                            <div className='row_social_footer'>
                                <img src={facebookIcon} />
                                <img src={instagramIcon} />
                                <img src={youtubeIcon} />
                                <img src={whatsapp} />

                            </div>

                        </div>


                    </div>


                </div>

                <div className='columBottom_footer'>

                    <p>Copyright © 2024 Higimais </p>


                </div>

            </footer>
        </>
    )
}

export default Landing
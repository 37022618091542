import React,{createContext,useState} from 'react'
export const GlobalContext = createContext({})

function GlobalProvider({children}){

    const [sidebarState,setSidebarState] = useState(false)

    const handleSidebar = (state) =>{
        setSidebarState(state)
    }


    return(
    <GlobalContext.Provider value={{handleSidebar,sidebarState}}>
        {children}
    </GlobalContext.Provider>
    )

}

export default GlobalProvider
import React,{useState}from 'react'
import image from '../../images/png/fralda-geriatrica-masculina-protect-plus-com-8-unidades-tamanho-p-m-plenitud-2db.jpg'
import cartImage from '../../images/png/cart.png'
import { Rating } from 'react-simple-star-rating'

const Card = ({ discount,star,image,description,value }) => {

    const [rating, setRating] = useState(0)

  // Catch Rating value
  const handleRating = (rate) => {
    setRating()

    // other logic
  }

  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)


    return (

        <div className='card'>
            {discount == true

                ?
                <div className='descount'>
                    <p>20% off</p>
                </div>
                :
                <></>
            }


            <div className='container_card_image'>
                <img src={image} />
            </div>
            <p className='textPrimaryCard'>{description}</p>
            <p className='textSecondaryCard'>R$ {value}</p>

            <div className='container_rating_stars'>
            <Rating
            initialValue={star}
            size={22}
            onClick={handleRating}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onPointerMove={onPointerMove}
            disableFillHover={true}
            allowHover={false}
            />
            </div>

            <div className='container_cart_btn'>
                <img src={cartImage} />
                <button>ADICIONAR </button>
            </div>
        </div>

    )
}

export default Card